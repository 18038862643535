// Styles

// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

#map {
  display: none;
}
#data {
  display: none;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div
  > div.layer-manager
  > div:nth-child(6) {
  display: none;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div
  > div.map-style-panel
  > div
  > div.side-panel-section {
  display: none;
}

body {
  background-color: #f4f4f4;
}

.add-data-button {
  display: none !important;
}

.side-panel-logo {
  display: none !important;
}

.side-panel__top__actions {
  float: right !important;
}

.side-panel__header__top {
  display: contents !important;
}

.side-side-panel__header__bottom {
  padding-top: 10px !important;
}

.remove-dataset {
  display: none !important;
}

.add-layer-button {
  display: none !important;
}

.map-style__layer-group__selector {
  display: none !important;
}

.add-map-style-button {
  display: none !important;
}

.layer__remove-layer {
  display: none !important;
}

.interaction-panel__header {
  display: none !important;
}

.split-map {
  display: none !important;
}

._loading_overlay_overlay {
  position: fixed !important;
  z-index: 10023000 !important;
}

#root
  > div
  > div
  > main
  > div
  > div
  > div.animated.fadeIn
  > div:nth-child(3)
  > div.col-sm-2
  > div
  > div.card-body
  > div.react-datepicker-wrapper
  > div
  > input {
  cursor: pointer !important;
}

.listGroupItemDataRecord:hover {
  background-color: #dadbdb;
}
.react-datepicker {
  display: grid !important;
}

.card {
  border: none;
  border-radius: 0.75rem;
  min-height: 190px;
}



#buttonsRow > div > div.card {
  color: #353535;
  background-color: #fff;
  border: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

body {
  font-family: "Roboto", sans-serif;
}

.card-header {
  background-color: #fff;
  border-bottom: none;
}

.h5ClassTotal {
  text-transform: capitalize;
  font-weight: 600;
  margin-right: 10px;
}

@media (max-width: 600px) {
  #buttonsRow { 
    margin-top: 15px;
  }
}

@media (max-width: 1199px) {
  .volumeButton {
    font-size: 2.3em;
  }

  .percentageButton {
    font-size: 1.7em;
    margin-left: "auto";
  }
}

@media (min-width: 1200px) {
  .volumeButton {
    font-size: 2.2vw;
  }

  .percentageButton {
    font-size: 1.5vw;
    margin-left: "auto";
  }
}

@media (max-width: 600px) {
  .container-fluid {
    padding: 0 15px!important;
  }
  #empresaNomeCol {
    display: flex!important;
    flex-direction: column;
    text-align: center;
  }
  #projetoNomeCol {
    display: flex!important;
    flex-direction: column;
    text-align: center;
  }
  #empresaNome {
    font-size: 8vw;
    text-align: center;
  }

  #projetoNome {
    margin-bottom: 20px;
    font-size: 8vw;
    text-align: center;
  }

  #empresaNome2 {
    margin: 10px 0!important;
    font-size: 5vw;
    text-align: center;
  }

  #projetoNome2 {
    margin: 10px 0!important;
    font-size: 5vw;
    text-align: center;
  }
}

#mapContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mapboxgl-ctrl-logo {
  width: 88px!important;
}

#voltarButton {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 300;
  text-transform: uppercase;
  width: fit-content;
  background-color: #29323c;
  border: none;
  border-radius: 0;
  color: #6a7485;
}

#voltarButton:hover {
  color: #ffffff;
  background-color: #3a4552;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__panel-header.side-side-panel__header
  > div
  > div.side-panel__top__actions {
  position: absolute;
  left: 270px;
  top: 30px;
}

@media (max-width: 450px) {
  .navbar-brand {
    left: 33%!important;
  }
}


#kepler-gl__trees > div.maps > div > div.map-control > div:nth-child(5) {
  display: none !important;
}
#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__panel-header.side-side-panel__header
  > div
  > div.side-panel__top__actions
  > div
  > div.side-panel__panel-header__action
  > p {
  display: none !important;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.layer-manager
  > div:nth-child(4)
  > div
  > div {
  pointer-events: none;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.layer-manager
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div.layer-panel__header__content
  > div.layer__title
  > div
  > input {
  pointer-events: none;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.layer-manager
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div.sc-psQdR.fWTQhu.layer-panel__header__actions
  > div.panel--header__action.layer__enable-config {
  display: none;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.layer-manager
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div.layer-panel__header__actions
  > div.panel--header__action.layer__visibility-toggle {
  pointer-events: all !important;
  cursor: pointer !important;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.layer-manager
  > div.source-data-catalog {
  display: none;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.filter-manager
  > div.add-filter-button.button.add-filter-button {
  width: fit-content;
}

#kepler-gl__trees
  > div:nth-child(2)
  > div
  > div
  > div.side-bar__inner
  > div.side-panel__content
  > div
  > div.layer-manager
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div.layer-panel__header__actions
  > div.panel--header__action.layer__enable-config {
  display: none;
}

.search-bar {
  width: 100%;
  border-radius: 15px;
  background: white;
  display: inline-flex;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.graph-bar {
  width: 100%;
  border-radius: 15px;
  background: white;
  margin: 20px 0;
  padding: 20px;
}

.right-menu {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
  
  .right-menu-row {
    align-items: center;
    display: inline-flex;
    margin: 10px 0;
    justify-content: space-between;
    select {
      margin-left: 20px;
    }
    @media (max-width: 600px) {
      display: flex;
      margin: 10px 0;
      flex-direction: column;
    }
  }
  .search-title {
    font-weight: 600;
    margin-bottom: 8px;
  }
  input {
    border: 1px solid #8c8c8c;
    border-radius: 4px;
    height: 32px;
    width: 90%;
    &:focus {
      outline: 0;
    }
  }
  i {
    margin-left: -24px;
    font-size: 16px;
    font-weight: 700;
  }
}

.divisor-search {
  background: rgba(0, 0, 0, 0.2);
  width: 2px;
  height: 100%;
  margin: 0 20px;
}

.results-tab {
  width: 100%;
  overflow: auto;
  margin-top: 20px;
  background: white;
  border-radius: 15px;
  height: fit-content;
  thead {
    th {
      border-top: none;
    }
  }
}

.results-total {
}

.search-input {
  display: flex;
  flex-direction: column;
  width: 50%;
  input {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: 30px;
  }
}
.search-selector {
  width: 50%;
}

.tabela-strong{
  font-weight: 300;
}

.nome-tabela {
  vertical-align: middle!important;
  font-size: 15px;
}

.arvore-title {
  font-size: 20px;
}

.arvore-cientifico {
  font-style: italic;
}

.closed-row {
  display: none;
}

.extracao-row {
  cursor: pointer;
  &:hover{
    background: #bbbbbb;
    
  }
}

.extracao-tree-row {
  background: rgb(234, 233, 233);
}

.clicked-row {
  background: #bbbbbb;
}
.strong-title {
  font-weight: 700;
}
